import apiInstance from "holocene-services/axios";
import {
  ICreateDeliveryTaskRequest,
  IDeliveryTask,
  IDeliveryTaskStatus,
  IDeliveryTaskType,
} from "./types";

class TaskListsService {
  async createTask(request: ICreateDeliveryTaskRequest, deliveryId: number) {
    const response = await apiInstance.post(`delivery/${deliveryId}/task`, request);
    return response.data;
  }

  async markTaskComplete(id: number, completed: boolean) {
    return apiInstance
      .patch(`/delivery-task/${id}`, {
        status: completed ? IDeliveryTaskStatus.Completed : IDeliveryTaskStatus.Open,
      })
      .then((response) => {
        return response.data;
      });
  }

  async getAllTasks(type: IDeliveryTaskType | null): Promise<IDeliveryTask[]> {
    const response = await apiInstance.get<IDeliveryTask[]>(`delivery-task`, {
      params: {
        type,
      },
    });

    return response.data.map((task) => ({
      ...task,
      dueDate: task.dueDate?.split("T")[0],
      createdAt: task.createdAt?.split("T")[0],
    }));
  }
}

export default new TaskListsService();

export { IDeliveryTaskStatus };

export type { ICreateDeliveryTaskRequest, IDeliveryTask };
