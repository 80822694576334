import { Fragment, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { INotificationTypes, useNotification } from "holocene-providers/common";

export type INotification = {
  message?: string;
  description?: string;
  type?: INotificationTypes;
  id: string;
  time?: number;
  isDeliveryNotification?: boolean;
  showCloseButton?: boolean;
};

const Notification: React.FC<INotification> = ({
  message = "Successfully saved!",
  description = "",
  type,
  id,
  time = 5000,
  isDeliveryNotification = false,
  showCloseButton = true,
}) => {
  const { removeNotification } = useNotification();

  useEffect(() => {
    setTimeout(() => removeNotification(id), time);
  }, []);

  return (
    <Transition
      show={!!id}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className={`max-w-sm w-full ${
          type === INotificationTypes.Warning
            ? "bg-holocene-orange"
            : isDeliveryNotification
            ? `bg-holocene-blue`
            : `bg-white`
        } shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden mb-2`}
      >
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              {type ? (
                <>
                  {type === INotificationTypes.Success && (
                    <CheckCircleIcon
                      className={`h-6 w-6 ${
                        isDeliveryNotification ? `text-white` : `text-green-400`
                      }`}
                      aria-hidden="true"
                    />
                  )}
                  {type === INotificationTypes.Error && (
                    <XMarkIcon
                      className={`h-6 w-6 ${
                        isDeliveryNotification ? `text-white` : `text-red-400`
                      }`}
                      aria-hidden="true"
                    />
                  )}
                  {(type === INotificationTypes.Info || type === INotificationTypes.Warning) && (
                    <ExclamationCircleIcon
                      className={`h-6 w-6 ${
                        isDeliveryNotification ? `text-white` : `text-blue-400`
                      }`}
                      aria-hidden="true"
                    />
                  )}
                </>
              ) : null}
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              {type && (
                <p
                  className={`text-sm font-medium ${
                    isDeliveryNotification ? `text-white` : `text-primary-text`
                  }`}
                >
                  {message}
                </p>
              )}
              {description && (
                <p
                  className={`mt-1 text-sm ${
                    isDeliveryNotification ? `text-white` : `text-secondary-text`
                  }`}
                >
                  {description}
                </p>
              )}
            </div>
            {showCloseButton && (
              <div className="ml-4 flex-shrink-0 flex">
                <button
                  className={`bg-transparent rounded-md inline-flex ${
                    isDeliveryNotification ? `text-white` : `text-gray-400`
                  } hover:text-secondary-text focus:outline-none focus:ring-2 focus:ring-offset-2`}
                  onClick={() => removeNotification(id)}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default Notification;
