import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import Loader from "./Loader";

export const RoutingLoader = () => {
  const router = useRouter();
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleStart = () => setLoading(true);
    const handleComplete = () => setLoading(false);

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  }, [router]);

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        setProgress((p) => {
          const delta = Math.random() * 30;
          if (p + delta >= 90) {
            return p;
          }
          return p + delta;
        });
      }, 400);

      return () => {
        setProgress(100);
        setTimeout(() => {
          clearInterval(interval);
          setProgress(0);
        }, 500);
      };
    }
  }, [loading]);

  return loading || progress ? (
    <div
      className="fixed top-0 h-0.5 bg-holocene-blue transition-all"
      style={{ width: `${progress}%` }}
    ></div>
  ) : null;
};
