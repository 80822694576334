import { Checkbox } from "holocene-components/common/Checkbox";
import { Radio } from "holocene-components/common/Radio";
import { useGetAllUserPermissionsByModule } from "holocene-hooks/users.hooks";
import { CustomerUser } from "holocene-services/user.service";
import { useState } from "react";
import { ModuleEnum } from "./UserDetailsModal";

enum PermissionType {
  readable = "readable",
  writable = "writable",
}

export type Props = {
  user?: CustomerUser;
  moduleName: ModuleEnum;
  assignedPermissions: { id: number; readable: boolean; writable: boolean }[];
  setAssignedPermissions: (value: { id: number; readable: boolean; writable: boolean }[]) => void;
  setIsDirty: (value: boolean) => void;
};

type PermissionProps = {
  permissionInfo: {
    label: string;
    value: number;
    readable: boolean;
    writable: boolean;
  };
  selectionType: PermissionType | null;
  onChange: (type: PermissionType | null) => void;
};

const Permission: React.FC<PermissionProps> = ({ permissionInfo, selectionType, onChange }) => {
  const [checked, setChecked] = useState(selectionType != null);
  const [permissionType, setPermissionType] = useState(selectionType);

  return (
    <div className="mb-5 flex w-full">
      <Checkbox
        label={permissionInfo.label}
        className="flex-1"
        labelClassName="!text-holocene-navy"
        checked={checked}
        onChange={() => {
          setChecked(!checked);
          if (checked) {
            setPermissionType(null);
            onChange(null);
          } else {
            const newVal = permissionInfo.readable
              ? PermissionType.readable
              : PermissionType.writable;
            setPermissionType(newVal);
            onChange(newVal);
          }
        }}
      />
      <Radio
        label="Read"
        className="px-12"
        name={permissionInfo.label}
        value={PermissionType.readable}
        disabled={!checked || !permissionInfo.readable}
        checked={permissionType === PermissionType.readable}
        onChange={(evt) => {
          setPermissionType(evt.target.value);
          onChange(evt.target.value);
        }}
      />
      {permissionInfo.writable ? (
        <>
          <Radio
            label="Write"
            className="px-12"
            name={permissionInfo.label}
            value={PermissionType.writable}
            disabled={!checked}
            checked={permissionType === PermissionType.writable}
            onChange={(evt) => {
              setPermissionType(evt.target.value);
              onChange(evt.target.value);
            }}
          />
        </>
      ) : (
        <div className="px-20"></div>
      )}
    </div>
  );
};

export const UserPermissions: React.FC<Props> = ({
  user,
  moduleName,
  assignedPermissions,
  setAssignedPermissions,
  setIsDirty,
}) => {
  const { data: permissions } = useGetAllUserPermissionsByModule(moduleName);
  const permissionOptions = permissions?.map((permissionInfo) => {
    return {
      label: permissionInfo.displayName,
      value: permissionInfo.id,
      readable: permissionInfo.readable,
      writable: permissionInfo.writable,
    };
  });

  const selectionMap =
    assignedPermissions.reduce(
      (obj: Record<number, PermissionType>, permissionVal) => ({
        ...obj,
        [permissionVal.id]: permissionVal.writable
          ? PermissionType.writable
          : PermissionType.readable,
      }),
      {}
    ) ?? {};

  return (
    <>
      {permissionOptions?.map((permission) => (
        <Permission
          key={permission.value}
          permissionInfo={permission}
          onChange={(value: PermissionType | null) => {
            setIsDirty(true);
            const newlyAssignedPermissions = assignedPermissions.slice();
            const existsIndex = assignedPermissions.findIndex(({ id }) => id === permission.value);
            if (existsIndex !== -1) {
              newlyAssignedPermissions.splice(existsIndex, 1);
            }
            if (value) {
              newlyAssignedPermissions.push({
                id: permission.value,
                readable: true,
                writable: value === PermissionType.writable,
              });
            }
            setAssignedPermissions(newlyAssignedPermissions);
          }}
          selectionType={selectionMap[permission.value] ?? null}
        />
      ))}
    </>
  );
};
