import React, { useState } from "react";
import UserAvatar from "holocene-components/common/UserAvatar";
import { IDeliveryTask, IDeliveryTaskStatus } from "holocene-services/deliveryTasks.service";
import { formatDateYYYYMMDD, formatStringDate } from "holocene-utils/date.utils";
import { ArrowLineUpRight, CheckCircle } from "phosphor-react";
import CustomTooltip from "holocene-components/common/CustomTooltip";
import Link from "next/link";

interface Props {
  task: IDeliveryTask;
  currentUserId: number | null;
  onComplete: (id: number, complete: boolean) => void;
  editable: boolean;
}

const DeliveryTask: React.FC<Props> = ({ task, currentUserId, onComplete, editable = false }) => {
  const [completed, setCompleted] = useState(task.status === IDeliveryTaskStatus.Completed);
  return (
    <div
      key={task.id}
      className="flex justify-between px-6 py-4 flex-col border-b border-b-strokes"
    >
      <div className="flex items-center mb-3">
        <div className="text-primary-text whitespace-normal  break-all text-sm">
          {task.task.split("\n").map((str, index) => (
            <div key={`task-${index}`}>{str}</div>
          ))}
        </div>
      </div>
      <div className="flex items-center">
        <UserAvatar size={7} name={task.user?.name || ""} className="mr-4" />
        {editable ? (
          <>
            <div className="flex mr-5 text-xs">
              <div className="text-secondary-text mr-1">Added</div>
              <div className="text-primary-text">{formatStringDate(task.createdAt)}</div>
            </div>
            {task.dueDate && (
              <div className="flex text-xs">
                <div className="text-secondary-text mr-1">Due</div>
                <div className="text-holocene-navy">{formatStringDate(task.dueDate)}</div>
              </div>
            )}
            <div className="grow"></div>
            <CheckCircle
              size={28}
              color={completed ? "#04CC83" : "#878A8A"}
              className="cursor-pointer"
              data-tip={completed ? "Mark incomplete" : "Mark complete"}
              onClick={() => {
                onComplete(task.id, !completed);
                setCompleted(!completed);
              }}
            />
          </>
        ) : (
          <>
            <div className="flex grow text-xs">
              <div className="text-secondary-text mr-1">Delivery</div>
              <div className="text-holocene-navy">
                {task.salesDelivery.deliveryNumber
                  ? task.salesDelivery.deliveryNumber
                  : task.salesDelivery.salesOrder.soNumber}
              </div>
            </div>
            <div>
              <ArrowLineUpRight
                size={24}
                className="cursor-pointer text-holocene-blue"
                data-tip="Open delivery in new tab"
                onClick={() => {
                  window.open(`/sales/delivery/${task.salesDeliveryId}`, "_blank");
                }}
              />
            </div>
          </>
        )}
      </div>
      <CustomTooltip />
    </div>
  );
};

export default DeliveryTask;
