import { CSSObjectWithoutAccentColor } from "holocene-components/common/ModeOfTransportDD/constants";
import { StylesConfig, components } from "react-select";

interface MultiSelectStylesStates {
  isFocused: boolean;
  isDisabled: boolean;
  isSelected?: boolean;
}

export type SelectVariant = "contained" | "underlined" | "borderless";
export type SelectSize = "sm" | "md";

export const incotermSelectProps: {
  styles: StylesConfig;
  components: Partial<typeof components>;
} = {
  styles: {
    option: (_baseStyles, state) => {
      return {
        width: "unset",
        border: "1px solid",
        borderColor: state.isSelected ? "#0055FF" : "#D7D8D8",
        borderRadius: "3px",
        padding: "2px 6px",
        color: state.isSelected ? "white" : "#0055FF",
        backgroundColor: state.isSelected ? "#0055FF" : "white",
        cursor: "pointer",
        fontSize: "12px",
      };
    },
    menuList: () => ({
      display: "flex",
      flexWrap: "wrap",
      gap: "8px 14px",
      padding: "14px",
      overflow: "auto",
      maxHeight: "200px",
    }),
  },
  components: {
    Option: components.Option,
  },
};

export const getSearchableSelectStyles = (variant?: SelectVariant): StylesConfig => ({
  indicatorSeparator: () => ({
    display: "none",
  }),
  input: (provided: CSSObjectWithoutAccentColor) => ({
    ...provided,
    "& .select__input": {
      boxShadow: "none",
    },
  }),
  ...(variant === "borderless"
    ? {
        control: (provided: CSSObjectWithoutAccentColor) => ({
          ...provided,
          border: 0,
          outline: 0,
          boxShadow: "none",
          background: "none",
        }),
        container: (provided: CSSObjectWithoutAccentColor) => ({
          ...provided,
          border: 0,
          outline: 0,
          boxShadow: "none",
        }),
      }
    : {
        control: (provided: CSSObjectWithoutAccentColor, state) => ({
          ...provided,
          boxShadow: "none",
          fontSize: "14px",
          borderRadius: "6px",
          opacity: state.isDisabled ? 0.5 : 1,
          background: "white",
        }),
      }),

  menuPortal: (provided: CSSObjectWithoutAccentColor) => ({
    ...provided,
    zIndex: 999,
    transform: "translateY(-7px)",
  }),
  option: (provided: CSSObjectWithoutAccentColor, state: MultiSelectStylesStates) => ({
    ...provided,
    backgroundColor: state.isDisabled
      ? "#e5e5e5"
      : state.isFocused
      ? "#E4EFFA !important"
      : "transparent",
    color: state.isDisabled ? "#a5a5a5" : "#373C3C",
    padding: "7px 14px",
  }),
  multiValue: (provided: CSSObjectWithoutAccentColor) => ({
    ...provided,
    background: "#102396",
    color: "white",
    borderRadius: "4px",
  }),
  multiValueLabel: (provided: CSSObjectWithoutAccentColor) => ({ ...provided, color: "white" }),
});

export const getMultiSelectStyles = (
  variant: SelectVariant,
  size: SelectSize = "md"
): StylesConfig => ({
  singleValue: () => ({
    color: variant === "underlined" ? "#0055FF" : undefined,
    textTransform: variant === "underlined" ? "uppercase" : undefined,
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: (provided: CSSObjectWithoutAccentColor) => ({
    ...provided,
    marginTop: "1px",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
  }),
  placeholder: (provided: CSSObjectWithoutAccentColor) => ({
    ...provided,
    color: variant === "underlined" ? "#0055FF" : "#878A8A",
    textTransform: variant === "underlined" ? "uppercase" : undefined,
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400,
    padding: 0,
    margin: 0,
  }),
  dropdownIndicator: (provided: CSSObjectWithoutAccentColor) => ({
    ...provided,
    color: "#0055FF",
  }),
  option: (provided: CSSObjectWithoutAccentColor, state: MultiSelectStylesStates) => ({
    ...provided,
    backgroundColor: state.isFocused || state.isSelected ? "#E4EFFA !important" : "transparent",
    color: "#373C3C",
    padding: "7px 14px",
  }),
  valueContainer: (provided: CSSObjectWithoutAccentColor) => ({
    ...provided,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    display: "flex",
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400,
    padding: 0,
  }),
  menuPortal: (provided: CSSObjectWithoutAccentColor) => ({
    ...provided,
    zIndex: 999,
  }),
  ...(variant === "underlined"
    ? {
        container: (base: CSSObjectWithoutAccentColor) => ({
          ...base,
          outline: "none",
          border: "none",
        }),
        control: (base: CSSObjectWithoutAccentColor) => ({
          ...base,
          border: "none",
          borderBottom: "1px solid #0055FF",
          borderRadius: 0,
          outline: "none",
          boxShadow: "none",
        }),
      }
    : variant === "borderless"
    ? {
        container: (base: CSSObjectWithoutAccentColor) => ({
          ...base,
          outline: "none",
          border: "none",
          background: "none",
        }),
        control: (base: CSSObjectWithoutAccentColor) => ({
          ...base,
          border: "none",
          borderRadius: 0,
          outline: "none",
          boxShadow: "none",
          background: "none",
        }),
      }
    : {
        control: (provided: CSSObjectWithoutAccentColor, state: MultiSelectStylesStates) => ({
          ...provided,
          borderRadius: "8px",
          boxShadow: "none",
          paddingLeft: "16px",
          display: "flex",
          alignItems: "center",
          backgroundColor: "white",
          opacity: state.isDisabled ? 0.5 : 1,
          borderColor: "rgba(215 215 216)",
          maxHeight: "38px",
          minHeight: size === "sm" ? "30px" : "38px",
        }),
      }),
});
export const getMultiLevelSelectStyles = (): StylesConfig => ({
  indicatorSeparator: () => ({
    display: "none",
  }),
  input: (provided: CSSObjectWithoutAccentColor) => ({
    ...provided,
    "& .select__input": {
      boxShadow: "none",
    },
  }),
  menu: (provided: CSSObjectWithoutAccentColor) => ({
    ...provided,
    marginTop: "1px",
    minWidth: "175px",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
  }),
  placeholder: (provided: CSSObjectWithoutAccentColor) => ({
    ...provided,
    color: "#878A8A",
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400,
    padding: "2px",
    margin: 0,
  }),
  dropdownIndicator: (provided: CSSObjectWithoutAccentColor) => ({
    ...provided,
    color: "#0055FF",
  }),
  option: (provided: CSSObjectWithoutAccentColor, state: MultiSelectStylesStates) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E4EFFA !important" : "transparent",
    color: "#373C3C",
    padding: "7px 14px",
  }),
  valueContainer: (provided: CSSObjectWithoutAccentColor) => ({
    ...provided,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontWeight: 400,
  }),
  menuPortal: (provided: CSSObjectWithoutAccentColor) => ({
    ...provided,
    zIndex: 999,
  }),

  control: (provided: CSSObjectWithoutAccentColor, state: MultiSelectStylesStates) => ({
    ...provided,
    borderRadius: "8px",
    boxShadow: "none",
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    opacity: state.isDisabled ? 0.5 : 1,
    borderColor: "rgba(215 215 216)",
    maxHeight: "38px",
    minHeight: "38px",
  }),
});
