import { Children, createContext, FC, useContext, useState } from "react";

export interface IOperationVersionContext {
  isPurchaseV3: boolean;
  setIsPurchaseV3: (value: boolean) => void;
}

export const OperationVersionContext = createContext<IOperationVersionContext>({
  isPurchaseV3: false,
  setIsPurchaseV3: () => {},
});

export const useOperationVersion = () => {
  const context = useContext(OperationVersionContext);
  if (context === undefined) {
    throw new Error("useOperationVersion must be used within a OperationVersionProvider");
  }
  return context;
};

type Props = {
  children: React.ReactNode;
};

export const OperationVersionProvider: FC<Props> = (props) => {
  const [isPurchaseV3, setIsPurchaseV3] = useState<boolean>(false);
  return (
    <OperationVersionContext.Provider value={{ isPurchaseV3, setIsPurchaseV3 }}>
      {props.children}
    </OperationVersionContext.Provider>
  );
};
