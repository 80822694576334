import apiInstance from "holocene-services/axios";

import {
  CurrentUserInfo,
  GetNylasMailsResponse,
  PutAssignedCountriesPayload,
  ICountry,
  CustomerUser,
  GetCustomerUserParams,
  IMemberData,
  IShipmentQueueItem,
  IShipmentQueueResponse,
  IShipmentsDispatchItem,
  IShipmentsDispatchResponse,
  ITradePolicy,
  ITradePolicyResponse,
  IUser,
  PatchNylasMailsReadStatusPayload,
  UpdateUserPayload,
  UserSettingsFilterState,
  PutAssignedCountriesResponse,
  CustomerDetails,
  PutAssignedModesOfTransportationPayload,
  PutAssignedModesOfTransportationResponse,
  UserRoleResponse,
  FeaturePermissionResponse,
  UpdateUserInformationPayload,
  UserTenant,
  UserTabPayload,
  UserTabConfiguration,
  UserTabConfigurationApi,
} from "./types";
import { ModuleEnum } from "holocene-components/settings/UserDetailsModal";
import { DeliveryListingTabConfiguration } from "holocene-components/delivery/DeliveryListing/TabForm/constants";
class AdminService {
  async getCountries() {
    const response = await apiInstance.get(`/countries`);
    return response.data;
  }

  async getCustomerUsers(filterState: UserSettingsFilterState) {
    const params: GetCustomerUserParams = {
      ...filterState,
      customerIds: filterState.customerIds.map((c) => c.id).toString() || undefined,
      countryIds: filterState.countryIds.map((c) => c.value).toString() || undefined,
    };
    const response = await apiInstance.get<CustomerUser[]>(`/customers/users`, { params });
    return response.data;
  }

  async updateAssignedCountries(params: PutAssignedCountriesPayload) {
    const response = await apiInstance.put<PutAssignedCountriesResponse>(
      "/user/assigned-countries",
      params
    );
    return response.data;
  }

  async updateAssignedModesOfTransportation(params: PutAssignedModesOfTransportationPayload) {
    const response = await apiInstance.put<PutAssignedModesOfTransportationResponse>(
      "/user/assigned-transportation-modes",
      params
    );
    return response.data;
  }

  async updateUserInformation(userId: number, params: UpdateUserInformationPayload) {
    const response = await apiInstance.patch(`/user/${userId}/userInfo`, params);
    return response.data;
  }

  async createNewUser(params: UpdateUserInformationPayload) {
    const response = await apiInstance.post(`/user/invite`, params);
    return response.data;
  }

  async deleteUser(userId: number) {
    const response = await apiInstance.delete(`/user/${userId}`);
    return response.data;
  }

  async getCustomers() {
    const response = await apiInstance.get(`/customers`);
    return response.data as CustomerDetails;
  }

  async updateCustomers(values: any) {
    const response = await apiInstance.patch(`/customers`, values);
    return response.data;
  }

  async updateUserInfo(values: UpdateUserPayload) {
    const response = await apiInstance.patch(`/user/updateEmailReminder`, values);
    return response.data;
  }

  async getTradePolicies(params: {
    skip: number;
    take: number;
    originCountryNames?: string[];
    destinationCountryNames?: string[];
    hsCode?: string;
  }): Promise<ITradePolicyResponse> {
    const response = await apiInstance.get(`/trade-policies`, {
      params,
    });
    return response.data;
  }

  async getTradePoliciesCount() {
    const response = await apiInstance.get(`/trade-policies/count`);
    return response.data;
  }

  async getPortCongestions(countryISOCode: string) {
    const response = await apiInstance.get(`/port-congestions?countryISOCode=${countryISOCode}`);
    return response.data;
  }

  async getIncoterms() {
    const response = await apiInstance.get(`/incoterms`);
    return response.data;
  }

  async getNylasMails(
    skip: number,
    take: number,
    shipmentId: number
  ): Promise<GetNylasMailsResponse> {
    const response = await apiInstance.get(
      `/nylas/mails?skip=${skip}&take=${take}&shipmentId=${shipmentId}`
    );
    return response.data;
  }

  async getNylasMailsAttachments(fileId: string) {
    const response = await apiInstance.get(`/nylas/mails/files/${fileId}`);
    return response.data;
  }

  async getNylasActive(): Promise<{ active: boolean }> {
    const response = await apiInstance.get(`/nylas/active`);
    return response.data;
  }

  async nylasDisconnect() {
    const response = await apiInstance.delete(`/nylas/logout`);
    return response.data;
  }

  async nylasConnect() {
    const response = await apiInstance.get(`/nylas/login`);
    return response.data;
  }

  async patchNylasMailsReadStatus(payload: PatchNylasMailsReadStatusPayload) {
    return apiInstance.patch("/nylas/mails", payload);
  }

  async postNylasMail(payload: FormData) {
    return apiInstance.post("/nylas/mails", payload);
  }

  async deleteNylasMail(payload: string[]) {
    return apiInstance.delete("/nylas/mails", { data: { nylasThreadIds: payload } });
  }

  async getUserInfo(): Promise<CurrentUserInfo> {
    const response = await apiInstance.post(`/user/me`);
    return response.data as CurrentUserInfo;
  }

  async getAllUserRoles(moduleName: ModuleEnum): Promise<UserRoleResponse[]> {
    const response = await apiInstance.get(`user-roles/all?moduleName=${moduleName}`);
    return response.data;
  }

  async getAllUserPermissions(moduleName: ModuleEnum): Promise<FeaturePermissionResponse[]> {
    const response = await apiInstance.get(`user-permissions/all?moduleName=${moduleName}`);
    return response.data;
  }

  async getUserTenants(): Promise<UserTenant[]> {
    const response = await apiInstance.get(`/user/tenants`);
    return response.data;
  }

  async getMemberData() {
    const response = await apiInstance.get<IMemberData[]>(`/dashboards/workloads`);
    return response.data;
  }

  async getShipmentsQueue() {
    const response = await apiInstance.get<IShipmentQueueResponse>(`/dashboards/shipments-queue`);
    return response.data;
  }

  async getShipmentsDispatch() {
    const response = await apiInstance.get<IShipmentsDispatchResponse>(
      `/dashboards/shipments-dispatch`
    );
    return response.data;
  }

  async getUsers() {
    const response = await apiInstance.get<IUser[]>(`/user`);
    return response.data;
  }

  async nylasReply(values: FormData) {
    const response = await apiInstance.post(`/nylas/mails`, values);
    return response.data;
  }

  async uploadSignature(file: File, customerId: number) {
    const formdata = new FormData();
    formdata.append("file", file);
    formdata.append("customerId", customerId.toString());
    return apiInstance.post("/user/signature", formdata).then((res) => res.data.signatureUrl);
  }

  async getSignature(signatureUrl: string) {
    return apiInstance
      .get("/user/signature/download", { params: { s3Uri: signatureUrl } })
      .then((response) => {
        return new Promise((res) => {
          const array = new Uint8Array(response.data.data);
          const fileBlob = new Blob([array], { type: "image/jpeg" });
          const reader = new FileReader();
          reader.onload = () => {
            res(reader.result as string);
          };
          reader.readAsDataURL(fileBlob);
        });
      })
      .catch(() => "") as Promise<string>;
  }

  async deleteSignature() {
    return apiInstance
      .delete("/user/signature")
      .then((response) => {})
      .catch(() => "");
  }

  async getUserTabs(params: { listingPage?: "in_queue" | "dispatched"; moduleName?: ModuleEnum }) {
    return apiInstance.get("/user-tabs", { params }).then((res) => {
      const data = res.data as UserTabConfigurationApi[];
      return data.map((entry) => ({
        ...entry,
        tabConfiguration: JSON.parse(entry.tabConfiguration),
      })) as UserTabConfiguration[];
    });
  }

  async postUserTab(
    listingPage: "in_queue" | "dispatched",
    moduleName: ModuleEnum,
    body: DeliveryListingTabConfiguration
  ) {
    return apiInstance.post("/user-tabs", {
      listingPage,
      moduleName,
      tabConfiguration: JSON.stringify({
        ...body,
        filterState: {
          ...body.filterState,
          alerts: body.filterState.alerts?.map((opt) => ({ label: opt.label, value: opt.value })),
          owner: body.filterState.owner?.map((opt) => ({ label: opt.label, value: opt.value })),
        },
      }),
    });
  }

  async patchUserTab(id: number, body: DeliveryListingTabConfiguration) {
    return apiInstance.patch(`/user-tabs/${id}`, {
      tabConfiguration: JSON.stringify({
        ...body,
        filterState: {
          ...body.filterState,
          alerts: body.filterState?.alerts?.map((opt) => ({ label: opt.label, value: opt.value })),
          owner: body.filterState?.owner?.map((opt) => ({ label: opt.label, value: opt.value })),
        },
      }),
    });
  }

  async deleteTab(id: number) {
    return apiInstance.delete(`/user-tabs/${id}`);
  }
}

function mockApiCall(data: any) {
  return new Promise((res) => setTimeout(() => res(data), 1500));
}

export default new AdminService();

export type {
  IShipmentsDispatchItem,
  IShipmentQueueItem,
  IMemberData,
  ITradePolicy,
  IUser,
  ICountry,
  GetCustomerUserParams,
  CustomerUser,
  PutAssignedCountriesPayload,
  PutAssignedCountriesResponse,
};
