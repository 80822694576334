import moment, { Moment } from "moment";
import "moment/min/locales";
import { tz } from "moment-timezone";
import zones from "moment-timezone/data/meta/latest.json";
import getDay from "date-fns/getDay";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import setSeconds from "date-fns/setSeconds";

class DateUtils {
  private isLocaleSet = false;
  public locale = "en";

  constructor() {}

  /**
   * This returns the date picker format used by the react-date-picker. It differs slightly from
   * moment's date format, because it doesn't use moment internally. It uses dd instead of DD,
   * and yyyy instead of YYYY.
   */
  public getDatePickerFormat() {
    if (!this.isLocaleSet) this.setLocale();
    return moment
      .localeData(this.locale)
      .longDateFormat("L")
      .replace("DD", "dd")
      .replace("YYYY", "yyyy");
  }

  public getLocaleDateFormat() {
    if (!this.isLocaleSet) this.setLocale();
    const l = moment.localeData(this.locale);
    return l.longDateFormat("L");
  }
  public getLocaleDateTimeFormat() {
    if (!this.isLocaleSet) this.setLocale();
    const l = moment.localeData(this.locale);
    return (
      l.longDateFormat("L").replaceAll("D", "d").replaceAll("Y", "y") +
      " " +
      l.longDateFormat("LT").replaceAll("A", "a")
    );
  }

  /**
   * Sets all the locale dependent values after the browser window has loaded
   */
  setLocale() {
    const browserLocale = window.navigator.language;

    const timezone = tz.guess();
    const zone = (zones as any)[timezone];
    const countryCode = zone?.countries[0];
    // Choose timezone locale - browser locale kept as a fallback
    const locale = countryCode || browserLocale;
    moment.locale(locale);
    this.locale = locale;
    this.isLocaleSet = true;
  }

  /**
   * Accepts a date string as input, and converts it to the standard date format used across the app
   * This is based on the locale of the user's browser - eg. 'MM/DD/YYYY' for en-US, 'DD/MM/YYYY' for en-GB
   */
  public formatStringDate = (
    date?: string | Date,
    format: string = "L",
    timezone?: string
  ): string => {
    if (!this.isLocaleSet) {
      this.setLocale();
    }
    if (timezone) return date ? moment.tz(date, timezone).format(format) : "";
    return date ? moment(date).format(format) : "";
  };

  /**
   * Accepts a moment instance as input, and converts it to the standard date format used across the app
   * This is based on the locale of the user's browser - eg. 'MM/DD/YYYY' for en-US, 'DD/MM/YYYY' for en-GB
   */
  public formatMomentDate = (moment: Moment, format: string = "L"): string => {
    if (!this.isLocaleSet) {
      this.setLocale();
    }
    return moment.format(format);
  };

  /**
   * Accepts a date string as input, and converts it to the standard date-time format used across the app
   * This is based on the locale of the user's browser -
   * eg. 'MM/DD/YYYY h:mm A' for en-US, 'DD/MM/YYYY h:mm A' for en-GB
   */
  public formatStringDateTime = (date: string): string => {
    if (!this.isLocaleSet) {
      this.setLocale();
    }
    return moment(date).format("L LT");
  };

  /**
   * Accepts a moment instance as input, and converts it to the standard date-time format used across the app
   * This is based on the locale of the user's browser -
   * eg. 'MM/DD/YYYY, h:mm A' for en-US, 'DD/MM/YYYY, h:mm A' for en-GB
   */
  public formatMomentDateTime = (moment: Moment, format: string = "L, LT"): string => {
    if (!this.isLocaleSet) {
      this.setLocale();
    }
    return moment.format(format);
  };

  public formatDateYYYYMMDD = (date?: Date | null | string): string => {
    return date ? moment(date).format("YYYY-MM-DD") : "";
  };

  public formatDateYYYYMMDDHHmm = (date?: Date, timezone?: string): string => {
    if (timezone) {
      const dateWithoutTimezone = moment(date).format("YYYY-MM-DD HH:mm");
      return date ? moment.tz(dateWithoutTimezone, timezone).format("YYYY-MM-DD HH:mm Z") : "";
    }
    return date ? moment(date).format("YYYY-MM-DD HH:mm Z") : "";
  };

  isWeekday = (date: Date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };

  excludeNineToTwo = (): Date[] => {
    const rangeOfTimes: Date[] = [21, 22, 23, 24, 0, 1, 2].flatMap((hour) => {
      if (hour === 2) return setSeconds(setHours(setMinutes(new Date(), 0), hour), 0);
      const times = [0, 15, 30, 45].map((minute) => {
        return setSeconds(setHours(setMinutes(new Date(), minute), hour), 0);
      });
      return times;
    });
    return rangeOfTimes;
  };
}

export const dateUtils = new DateUtils();

export const formatStringDate = dateUtils.formatStringDate;
export const formatMomentDate = dateUtils.formatMomentDate;
export const formatStringDateTime = dateUtils.formatStringDateTime;
export const formatMomentDateTime = dateUtils.formatMomentDateTime;
export const formatDateYYYYMMDD = dateUtils.formatDateYYYYMMDD;
export const formatDateYYYYMMDDHHmm = dateUtils.formatDateYYYYMMDDHHmm;
export const isWeekday = dateUtils.isWeekday;
export const excludeNineToTwo = dateUtils.excludeNineToTwo;
