import { QUERY_CONSTANTS } from "holocene-constants/queryConstants";
import { decodeBase64ToJSON, encodeJSONToBase64, isDeepEqual } from "holocene-utils/helpers";
import { OperationType } from "holocene-utils/types";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useGetCurrentUserInfo } from "./users.hooks";
import { ModuleEnum } from "holocene-components/settings/UserDetailsModal";

//Helps us carry out a function with a debounce interval:
//Once the function is called and timeout is set, subsequent calls to the
//function reset the timeout and queue the function with the most recent arguments.
//After the debounce delay lapses, with no new calls made to the fn, the function is called
//with the last arguments that were passed to it
export const useDebouncedCallback = (fn: (...args: any) => void, delay: number, deps: any[]) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  return useCallback((...args: any) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      fn(...args);
    }, delay);
  }, deps);
};
export const useDebounceValue = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

//Reads the filter state from the url of the page and converts it to a payload.
//Returns the current filter state encoded in the url and a debounced setter function
//that can be called everytime the form values change - similar to useState.
//This hook should be called at the top level of the component to ensure correct results
export function usePersistedFilterState<T>(defaultState: T): [T, (values: T) => void] {
  const isLoadedFromUrl = useRef(false);
  const urlFilters = useRef<T>(defaultState);
  const router = useRouter();
  const query = useRef(router.query);
  query.current = router.query;

  if (!isLoadedFromUrl.current) {
    const { filter } = router.query;
    if (filter) {
      urlFilters.current = decodeBase64ToJSON(filter as string);
    }
    isLoadedFromUrl.current = true;
  }

  const setUrlFilterState = useDebouncedCallback(
    (filterState) => {
      if (isLoadedFromUrl.current) {
        const isSameAsOld = isDeepEqual(filterState, urlFilters.current);
        if (!isSameAsOld) {
          let filterString = "";
          const isSameAsDefault = isDeepEqual(filterState, defaultState);
          if (isSameAsDefault) {
            filterString = "";
          } else {
            filterString = encodeJSONToBase64(filterState);
          }
          urlFilters.current = filterState;

          router.replace({
            query: filterString ? { ...query.current, filter: filterString } : query.current,
          });
        }
      }
    },
    500,
    []
  );

  return [urlFilters.current, setUrlFilterState];
}

export const useActiveOperation = () => {
  const queryClient = useQueryClient();
  const { asPath } = useRouter();
  const isSalesPath = asPath.startsWith("/sales");
  const isPurchasePath = asPath.startsWith("/purchase");
  const { data: userInfo } = useGetCurrentUserInfo();
  let currentlyActiveOperation = localStorage.getItem("activeOperation");
  if (!currentlyActiveOperation && userInfo) {
    const isSupportUser = userInfo?.roles.includes("Admin");
    const hasSalesRole =
      userInfo?.detailInfo.Roles.some(({ Role }) => Role.moduleName === ModuleEnum.sales) ?? false;
    const operationToAssign =
      isSupportUser || hasSalesRole ? OperationType.SALES : OperationType.PURCHASE;
    localStorage.setItem("activeOperation", operationToAssign);
    currentlyActiveOperation = operationToAssign;
  }
  const activeOperation = isSalesPath
    ? OperationType.SALES
    : isPurchasePath
    ? OperationType.PURCHASE
    : currentlyActiveOperation || OperationType.SALES;

  if (currentlyActiveOperation !== activeOperation) {
    queryClient.invalidateQueries(QUERY_CONSTANTS.CURRENT_USER_INFO);
  }

  useEffect(() => {
    if (isSalesPath) {
      localStorage.setItem("activeOperation", OperationType.SALES);
    }
    if (isPurchasePath) {
      localStorage.setItem("activeOperation", OperationType.PURCHASE);
    }
  }, [isSalesPath, isPurchasePath]);

  return activeOperation as OperationType;
};
