import { useQuery, useMutation, useQueryClient } from "react-query";
import { QUERY_CONSTANTS } from "holocene-constants/queryConstants";
import { ISelectWithTagsOptions } from "holocene-components/common/new/SelectWithTags";
import userService, { ICountry } from "holocene-services/user.service";
import {
  CurrentUserInfo,
  PutAssignedCountriesPayload,
  GetCustomerUserParams,
  NylasMailThread,
  UpdateUserPayload,
  UserSettingsFilterState,
  CustomerUser,
  PutAssignedModesOfTransportationPayload,
  UpdateUserInformationPayload,
} from "holocene-services/user.service/types";
import { useEffect, useState } from "react";
import { useDebouncedCallback } from "./utils.hooks";
import { INotificationTypes, useNotification } from "holocene-providers/common";
import { AxiosError } from "axios";
import { ModuleEnum } from "holocene-components/settings/UserDetailsModal";

interface IPortCongestionItem {
  portName: string;
  averageDelay: number;
}

interface INylasEmail {
  shipmentId: number | string | string[] | undefined;
  isActive?: boolean;
}

export const useGetCountries = () => {
  return useQuery(
    [QUERY_CONSTANTS.COUNTRIES],
    async () => {
      const response = (await userService.getCountries()) as ICountry[];
      return response.sort((a, b) => a.officialName.localeCompare(b.officialName));
    },
    {
      refetchOnMount: "always",
    }
  );
};

export const useGetCustomers = () => {
  return useQuery([QUERY_CONSTANTS.CUSTOMERS], () => userService.getCustomers(), {
    refetchOnMount: "always",
  });
};

export const useUpdateCustomer = () => useMutation((values) => userService.updateCustomers(values));

export const useUpdateUserInfo = () => {
  const queryClient = useQueryClient();
  return useMutation((values: UpdateUserPayload) => userService.updateUserInfo(values), {
    onSuccess: (data) => {
      queryClient.setQueryData(
        QUERY_CONSTANTS.CURRENT_USER_INFO,
        (oldData: CurrentUserInfo | undefined) => {
          return {
            ...oldData!,
            detailInfo: { ...oldData?.detailInfo, ...data },
            //TODO: Restructure code to avoid this. This is necessary because react query doesn't trigger rerenders if the json evaluates to the same as the old value
            _randomKey: Math.random(),
          };
        }
      );
    },
  });
};

export const useGetPortCongestion = (country?: string) => {
  return useQuery<IPortCongestionItem[]>(
    [QUERY_CONSTANTS.PORT_CONGESTION, country],
    () => (country ? userService.getPortCongestions(country) : []),
    { retry: false }
  );
};

export const useGetIncoterms = () => {
  return useQuery<ISelectWithTagsOptions[]>(
    [QUERY_CONSTANTS.INCOTERMS],
    async () => {
      const response = await userService.getIncoterms();
      return response.map(({ id, code }: { id: number; code: string }) => ({
        value: id,
        label: code,
      }));
    },
    {
      initialData: [],
      refetchOnMount: "always",
    }
  );
};

export const useCountTradePolicies = () => {
  return useQuery([QUERY_CONSTANTS.TRADE_POLICY_COUNT], () => userService.getTradePoliciesCount(), {
    refetchOnMount: "always",
  });
};

export const useGetUsers = () => {
  return useQuery([QUERY_CONSTANTS.USERS], () => userService.getUsers(), {
    refetchOnMount: "always",
  });
};

export const useGetUserInfo = () => {
  return useQuery(
    [QUERY_CONSTANTS.USER_INFO],
    async () => {
      const response = await userService.getUserInfo();
      if (response?.userId) {
        localStorage.setItem("userid", JSON.stringify({ id: response?.userId }));
      }
      return response;
    },
    {
      enabled: true,
    }
  );
};

export const useGetCurrentUserInfo = () => {
  return useQuery(QUERY_CONSTANTS.CURRENT_USER_INFO, userService.getUserInfo);
};

export const useGetAllUserRolesByModule = (moduleName: ModuleEnum) => {
  return useQuery([QUERY_CONSTANTS.ALL_USER_ROLES, moduleName], () =>
    userService.getAllUserRoles(moduleName)
  );
};

export const useGetAllUserPermissionsByModule = (moduleName: ModuleEnum) => {
  return useQuery([QUERY_CONSTANTS.ALL_USER_PERMISSIONS, moduleName], () =>
    userService.getAllUserPermissions(moduleName)
  );
};

export const useGetUserTenants = () => {
  return useQuery(QUERY_CONSTANTS.USER_TENANTS, userService.getUserTenants);
};

export const useGetNylasMails = ({ shipmentId, isActive }: INylasEmail) => {
  return useQuery(
    [QUERY_CONSTANTS.NYLAS_MAILS, shipmentId],
    () => userService.getNylasMails(0, 20, Number(shipmentId)),
    {
      enabled: !!isActive,
      keepPreviousData: true,
    }
  );
};

export const useGetNylasActive = () => {
  return useQuery([QUERY_CONSTANTS.NYLAS_ACTIVE], () =>
    userService.getNylasActive().then((res) => res.active)
  );
};

export const usePatchNylasMailsReadStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(userService.patchNylasMailsReadStatus, {
    onSuccess: (_, variables) => {
      queryClient.setQueriesData(
        QUERY_CONSTANTS.NYLAS_MAILS,
        (oldData: { threads: NylasMailThread[] } | undefined) => {
          if (oldData) {
            return {
              ...oldData,
              threads: oldData.threads.map((thread) => {
                if (variables.nylasThreadIds.includes(thread.id)) {
                  return { ...thread, unread: variables.unread };
                } else {
                  return thread;
                }
              }),
            };
          }
          return { threads: [] };
        }
      );
    },
  });
};

export const usePostNylasMail = () => {
  return useMutation((paylaod: FormData) => userService.postNylasMail(paylaod));
};

export const useDeleteNylasMail = () => {
  return useMutation((paylod: string[]) => userService.deleteNylasMail(paylod));
};

export const useGetNylasMailsAttachments = (fileId: string) => {
  return useQuery([QUERY_CONSTANTS.NYLAS_MAILS_FILES], () =>
    userService.getNylasMailsAttachments(fileId).then((res) => res.active)
  );
};

export const usePostNylasReply = () =>
  useMutation((values: FormData) => {
    return userService.nylasReply(values);
  });

export const useGetCustomerUsers = (filters: UserSettingsFilterState) => {
  const [params, setParams] = useState(filters);

  const handleFilterChange = useDebouncedCallback(setParams, 800, []);

  useEffect(() => {
    handleFilterChange(filters);
  }, [filters]);

  const { data, isLoading, isFetching } = useQuery(
    [QUERY_CONSTANTS.CUSTOMER_USERS, params],
    () => userService.getCustomerUsers(params),
    { keepPreviousData: true }
  );

  return { data: data || [], isLoading: isLoading || isFetching };
};

export const usePutAssignedCountries = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (params: PutAssignedCountriesPayload) => userService.updateAssignedCountries(params),
    {
      onSuccess: (res) => {
        queryClient.setQueriesData([QUERY_CONSTANTS.CUSTOMER_USERS], (users?: CustomerUser[]) => {
          return users!.map((user) =>
            user.userId === res.id
              ? { ...user, userAssignedCounties: res.userAssignedCounties }
              : user
          );
        });
      },
    }
  );
};

export const usePutAssignedModesOfTransportation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (params: PutAssignedModesOfTransportationPayload) =>
      userService.updateAssignedModesOfTransportation(params),
    {
      onSuccess: (res) => {
        queryClient.setQueriesData([QUERY_CONSTANTS.CUSTOMER_USERS], (users?: CustomerUser[]) => {
          return users!.map((user) => {
            return user.userId === res.id
              ? {
                  ...user,
                  UserAssignedModesOfTransportation: res.UserAssignedModesOfTransportation,
                }
              : user;
          });
        });
      },
    }
  );
};

export const useUpdateUserInformation = () => {
  return useMutation((params: { userId: number; requestData: UpdateUserInformationPayload }) =>
    userService.updateUserInformation(params.userId, params.requestData)
  );
};

export const useCreateNewUser = () => {
  return useMutation((params: { requestData: UpdateUserInformationPayload }) =>
    userService.createNewUser(params.requestData)
  );
};

export const useDeleteUser = () => {
  return useMutation((params: { userId: number }) => userService.deleteUser(params.userId));
};

export const useGetUserSignature = () => {
  const { data: customerInfo } = useGetCurrentUserInfo();
  const signatureUrl = customerInfo?.detailInfo.signatureUrl;
  return useQuery(
    [QUERY_CONSTANTS.USER_SIGNATURE, signatureUrl],
    () => userService.getSignature(signatureUrl!),
    {
      enabled: Boolean(signatureUrl),
    }
  );
};

export const useUploadSignature = (customerId?: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (file: File) =>
      customerId ? userService.uploadSignature(file, customerId) : Promise.resolve(""),
    {
      onSuccess: (res) => {
        queryClient.setQueryData(
          QUERY_CONSTANTS.CURRENT_USER_INFO,
          (oldData?: CurrentUserInfo) => ({
            ...oldData!,
            detailInfo: { ...oldData!.detailInfo, signatureUrl: res },
          })
        );
      },
    }
  );
};

export const useDeleteSignature = () => {
  const queryClient = useQueryClient();
  return useMutation(userService.deleteSignature, {
    onSuccess: () => {
      queryClient.setQueryData(QUERY_CONSTANTS.CURRENT_USER_INFO, (oldData?: CurrentUserInfo) => ({
        ...oldData!,
        detailInfo: { ...oldData!.detailInfo, signatureUrl: "" },
      }));
    },
  });
};

export const useGetUserTabs = (
  listingPage?: "in_queue" | "dispatched",
  moduleName?: ModuleEnum
) => {
  return useQuery([QUERY_CONSTANTS.USER_TABS, listingPage, moduleName], () =>
    userService.getUserTabs({ listingPage, moduleName })
  );
};
