import { AxiosRequestHeaders } from "axios";

export function operationModuleHeader(): AxiosRequestHeaders {
  try {
    const activeOperation = localStorage.getItem("activeOperation");
    if (typeof activeOperation !== "string") throw new Error("Active operation module not found");

    return { 'X-Operation-Module': activeOperation };
  } catch {
    return {};
  }
}
