import React, { FC, useState, useEffect, useCallback } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CaretDoubleRight } from "phosphor-react";

import CustomTooltip from "holocene-components/common/CustomTooltip";

import deliveryTaskService, {
  ICreateDeliveryTaskRequest,
  IDeliveryTask,
  IDeliveryTaskStatus,
} from "holocene-services/deliveryTasks.service";

import {
  useCreateTask,
  useCompleteTask,
  useGetDeliveryTasks,
} from "holocene-hooks/deliveryTasks.hooks";
import TaskForm from "./DeliveryTaskForm";
import { INotificationTypes, useNotification } from "holocene-providers/common";
import DeliveryTask from "./DeliveryTask";
import { IDeliveryTaskType } from "holocene-services/deliveryTasks.service/types";
import { useQueryClient } from "react-query";
import { QUERY_CONSTANTS } from "holocene-constants/queryConstants";
import {
  IWebsocketAlertDelivery,
  WebsocketAlertModelName,
  WebsocketAlertAction,
  useAlertWebSocketDelivery,
} from "holocene-hooks/alertWebSocket.hooks";

type DeliveryTaskListProps = {
  deliveryId?: number | null;
  onClose: () => void;
  show: boolean;
  type?: IDeliveryTaskType;
  userIds?: number[];
  deliveryTasks?: IDeliveryTask[];
};

const DeliveryTaskList: FC<DeliveryTaskListProps> = ({
  deliveryId,
  onClose,
  show = true,
  type = IDeliveryTaskType.All,
  userIds = [],
  deliveryTasks,
}) => {
  const { addNotification } = useNotification();
  const mutationCreateTask = useCreateTask();
  const mutationCompleteTask = useCompleteTask();
  const queryClient = useQueryClient();
  const [currentUserId, setCurrentUserId] = useState<number>(-1);
  const [showCompleted, setShowCompleted] = useState(false);

  useEffect(() => {
    const contents = localStorage.getItem("userid");
    if (contents) {
      try {
        const parsed = JSON.parse(contents);
        if (parsed.id) {
          setCurrentUserId(parseInt(parsed.id, 10));
        }
      } catch {
        //
      }
    }
  }, []);

  const refetchData = () => {
    queryClient.refetchQueries([QUERY_CONSTANTS.DELIVERY_DETAILS, deliveryId]);
    queryClient.invalidateQueries([QUERY_CONSTANTS.DELIVERY_TASKS, deliveryId, type]);
  };

  const onAlert = useCallback(
    (alert: IWebsocketAlertDelivery) => {
      if (
        alert.modelName === WebsocketAlertModelName.DELIVERY_TASK &&
        alert.action === WebsocketAlertAction.UPDATE
      ) {
        if (alert.success && alert.data?.salesDeliveryId === deliveryId) {
          refetchData();
        }
      }
    },
    [deliveryId]
  );

  useAlertWebSocketDelivery(onAlert);

  const handleComplete = (id: number, completed: boolean) => {
    mutationCompleteTask.mutate(
      { id, completed },
      {
        onSuccess: () => {
          addNotification({
            type: INotificationTypes.Success,
            message: `Task marked ${completed ? "complete" : "incomplete"} successfully.`,
          });
          refetchData();
        },
        onError: () => {
          addNotification({
            type: INotificationTypes.Error,
            message: "Failed to change task status.",
          });
        },
      }
    );
  };

  const handleTaskAdd = (values: ICreateDeliveryTaskRequest) => {
    if (deliveryId) {
      mutationCreateTask.mutate(
        { request: values, deliveryId },
        {
          onSuccess: () => {
            addNotification({
              type: INotificationTypes.Success,
              message: "Task added successfully.",
            });
            refetchData();
          },
          onError: () => {
            addNotification({ type: INotificationTypes.Error, message: "Failed to add task." });
          },
        }
      );
    }
  };
  const filteredTasks = deliveryTasks?.filter((task) => {
    let isFiltered = true;

    if (!showCompleted) {
      isFiltered = task.status !== "completed";
    }
    if (isFiltered && userIds.length > 0) {
      isFiltered = userIds.includes(task.userId);
    }

    return isFiltered;
  });

  const renderTitle = () => (
    <Dialog.Title className="font-medium text-sm flex px-6 py-6 items-center justify-between">
      <div className="text-holocene-navy">
        {type === IDeliveryTaskType.DueToday
          ? "Tasks due Today"
          : type === IDeliveryTaskType.Upcoming
          ? "Tasks due Later"
          : "Task List"}{" "}
        ({filteredTasks?.length})
        <CaretDoubleRight
          className="w-4 h-4 inline-block ml-2 cursor-pointer"
          weight="bold"
          onClick={() => {
            onClose();
          }}
        />
      </div>
      {type === IDeliveryTaskType.All && (
        <div
          className="text-holocene-blue cursor-pointer"
          onClick={() => setShowCompleted(!showCompleted)}
        >
          {showCompleted ? "Hide Completed" : "Show Completed"}
        </div>
      )}
    </Dialog.Title>
  );

  const renderList = () => {
    if (!filteredTasks?.length) {
      return <div className="text-secondary-text text-center text-sm py-6">No tasks found</div>;
    }
    return filteredTasks.map((task) => (
      <DeliveryTask
        task={task}
        key={task.id}
        currentUserId={currentUserId}
        onComplete={handleComplete}
        editable={!!deliveryId}
      />
    ));
  };

  return (
    <Transition.Root show={show} as={React.Fragment}>
      <Dialog as="div" className="relative z-30" onClose={onClose}>
        <div className="fixed inset-0 overflow-hidden">
          <Dialog.Panel>
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
              <Transition.Child
                as={React.Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Overlay className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-y-auto shadow-xl bg-white">
                    {renderTitle()}
                    <div className="flex-1 overflow-y-auto flex flex-col h-full justify-between">
                      <div>{renderList()}</div>
                    </div>
                    {!!deliveryId && <TaskForm onAdd={handleTaskAdd} />}
                  </div>
                  <CustomTooltip />
                </Dialog.Overlay>
              </Transition.Child>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DeliveryTaskList;
