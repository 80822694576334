import { useMutation, useQueries, useQuery } from "react-query";
import deliveryTasksService, {
  ICreateDeliveryTaskRequest,
} from "holocene-services/deliveryTasks.service";
import { QUERY_CONSTANTS } from "holocene-constants/queryConstants";
import { IDeliveryTaskType } from "holocene-services/deliveryTasks.service/types";

export const useCreateTask = () =>
  useMutation(
    ({ request, deliveryId }: { request: ICreateDeliveryTaskRequest; deliveryId: number }) =>
      deliveryTasksService.createTask(request, deliveryId)
  );

export const useCompleteTask = () =>
  useMutation(({ id, completed }: { id: number; completed: boolean }) =>
    deliveryTasksService.markTaskComplete(id, completed)
  );

export const useGetDeliveryTasks = (type?: IDeliveryTaskType | null) => {
  return useQuery([QUERY_CONSTANTS.DELIVERY_TASKS, type], () =>
    deliveryTasksService.getAllTasks(type || null)
  );
};
